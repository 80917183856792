import React from 'react'
import logo from "../../assets/imgs/Logo light.png"
import pciDss from "../../assets/imgs/pciDss.png"
import wrong from "../../assets/icons/danger.svg"


function Wrong() {
    return (
        <div>
            <div className='md:border  flex flex-col gap-[16px] md:shadow-[0px_2px_10px_0px_rgba(0,0,0,0.15)]  border-[#1289D9] rounded-[16px] mt-[2%] py-[2%] px-[5%]  md:w-[45%] mx-[auto]'>
                <img className='w-[97px] mx-auto' src={logo} alt="" />
                <div className='flex items-center mx-auto gap-[20px] mt-[1%]'>
                    <img src={""} alt="" />
                    <h1 className='text-[#EF2323] text-[28px] font-bold flex items-start  gap-[12px]'><img src={wrong} alt="" />Oops! Something went wrong</h1>
                </div>
                <div className='h-[1px] bg-[#E7E8EA] my-[24px]'>

                </div>
                <p className='text-[20px] text-[#555A5F]'>Unfortunately, the service is not available at the moment. Try again in a few minutes</p>
            </div>
            <img className='mx-auto my-[10%] md:my-[2%]' src={pciDss} alt="" />
        </div>
    )
}

export default Wrong