import React, {useMemo, useState} from 'react';
import logo from "../../assets/imgs/Logo light.png";
import cardIcon from "../../assets/icons/card.svg";
import arrLeft from "../../assets/icons/arrow-left.svg";
import sms from "../../assets/icons/sms.svg";
import pciDss from "../../assets/imgs/pciDss.png";
import TextField from '@mui/material/TextField';
import visaicon from '../../assets/icons/visa.svg';
import humoicon from '../../assets/icons/humo.svg';
import uzcardicon from '../../assets/icons/uzcard.svg';
import axios from "axios";
import {toast, Toaster} from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";

function PaymentByCard() {
    const [isEnabled, setIsEnabled] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [extId, setExtId] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(false);
    const [owner, setOwner] = useState('');
    const [error, setError] = useState('');
    const [number, setNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [data, setData] = useState({});
    const [isCardNumberValid, setIsCardNumberValid] = useState(true);
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    const verification_id = urlParams.get('id');

    const navigate = useNavigate()
    const location = useLocation()

    const checkIconCard = (value) => {
        if (value.startsWith('8600')) {
            return uzcardicon;
        } else if (value.startsWith('6262')) {
            return uzcardicon;
        } else if (value.startsWith('9860')) {
            return humoicon;
        } else if (value.startsWith('4073')) {
            return humoicon;
        } else if (value.startsWith('5555')) {
            return humoicon;
        } else if (value.startsWith('5614')) {
            return uzcardicon;
        } else {
            return cardIcon;
        }
    };
    const [expiry, setExpiry] = useState("");

    function isValidLuhn(input) {
        const digits = input.replace(/\D/g, ''); // Remove non-digit characters
        let sum = 0;
        let shouldDouble = false;

        for (let i = digits.length - 1; i >= 0; i--) {
            let digit = parseInt(digits[i], 10);

            if (shouldDouble) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }

            sum += digit;
            shouldDouble = !shouldDouble;
        }

        return sum % 10 === 0;
    }

    const send_otp = (verification_id, number, expire, owner) => {
        setLoading(true)
        const url = 'https://gw.unipos.online/api/v1/jsonrpc'
        const headers = {
            'Content-Type': 'application/json'
        }
        const params = {
            "jsonrpc": "2.0",
            "id": randomInt,
            "method": "payment.send.otp",
            "params": {
                "payment_id": verification_id,
                "number": number,
                "expire": expire,
                "owner_name": owner
            }
        }
        axios.post(url, params, {headers})
            .then(response => {
                setData(response.data.result)
                if (response.data.result) {
                    navigate('/smsVerify', {state: {verification_id: verification_id, phone: phone}})
                } else {
                    toast.error(response?.data?.error?.message, {
                        style: {
                            borderRadius: '10px',
                            background: '#c0392b',
                            color: '#fff',
                        },
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
        setLoading(false)
    }

    const formatInput = (value) => {
        return value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
    };

    const reverseExpiryDate = (expiryDate) => {
        const cleanDate = expiryDate.replace('/', ''); // Remove the slash
        const [month, year] = [cleanDate.slice(0, 2), cleanDate.slice(2)];
        return `${year}${month}`;
    };

    const handleInputChange = (e) => {
        const {value} = e.target;
        const formattedValue = formatInput(value);
        setInputValue(formattedValue);
        const plainNumber = formattedValue.replace(/\s/g, '');

        if (plainNumber.length === 16) {
            setIsCardNumberValid(isValidLuhn(plainNumber));
            if (isValidLuhn(plainNumber)) {
                setNumber(plainNumber)
            } else {
                setOwner(''); // Reset owner if card number is invalid
            }
        } else {
            setOwner('');
            setIsCardNumberValid(true)
        }
    };

    const formatExpireInput = (event) => {
        return event.replace(/\D/g, '').substring(0, 4);
    };

    const handleChange = (event) => {
        let value = event.target.value;

        // Remove all non-digits and limit string length to 4
        value = value.replace(/\D/g, '').substring(0, 4);

        // Add a slash after the first two digits
        if (value.length > 2) {
            value = value.substring(0, 2) + '/' + value.substring(2);
        }

        setExpiry(value);
    };

    const [cvv, setCvv] = useState('');

    const handleChangeCvv = (event) => {
        let value = event.target.value;

        // Remove all non-digits and limit string length to 3
        value = value.replace(/\D/g, '').substring(0, 3);

        setCvv(value);
    };

    const randomInt = Math.floor(Math.random() * 1000);
    const fetch_info = (id) => {
        const url = 'https://gw.unipos.online/api/v1/jsonrpc'
        const headers = {
            'Content-Type': 'application/json'
        }
        const params = {
            "jsonrpc": "2.0",
            "id": randomInt,
            "method": "payment.fetch.info",
            "params": {
                "payment_id": id
            }
        }
        axios.post(url, params, {headers})
            .then(response => {
                setExtId(response?.data?.result?.ext_id)
                setAmount(response?.data?.result?.amount)
            })
            .catch(error => {
                console.log(error);
            })

    }


    const isVisaCard = inputValue.startsWith('4934'); // Check if it's a Visa card

    const isFormValid = !!inputValue && !!expiry && (!isVisaCard || !!cvv); // Example validation logic

    const closeTab = () => {
        window.location.href = 'about:blank';
    };

    useMemo(() => {
        fetch_info(verification_id)
    }, [])

    return (<div className='flex items-center justify-center flex-col '>
            <div className='flex items-center mt-[2%] sm:flex-col md:flex-row'>
                <div
                    className='border-y flex flex-col gap-[15px] shadow-[0px_2px_10px_0px_rgba(0,0,0,0.15)] border-l border-[#1289D9] rounded-l-[16px] py-[25px] px-[40px] w-[45%]'>
                    <h2 className='text-[#1289D9] text-[28px] font-bold'>Order summary</h2>
                    <h3 className='font-semibold text-[20px]'>
                        Order Description
                        <p className='text-[16px] font-normal'>Payment for order No. in the store</p>
                    </h3>
                    <h3 className='font-semibold text-[20px]'>
                        Order date
                        <p className='text-[16px] font-normal'>{new Date().getDate()}-{new Date().getMonth()}-{new Date().getFullYear()}</p>
                    </h3>
                    <div className='h-[1px] bg-[#E7E8EA]'>

                    </div>
                    <h3 className='font-semibold text-[20px]'>
                        Comission
                        <p className='text-[16px] font-normal'>0 UZS</p>
                    </h3>
                    <h3 className='font-semibold text-[20px]'>
                        Total amount
                        <p className='text-[24px] text-[#1289D9]'>{(amount / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} UZS</p>
                    </h3>
                    <div className='flex items-center justify-between pt-[20%]'>
                        <p onClick={closeTab} className='flex items-center text-[14px] text-[#B1B1B4] cursor-pointer'>
                            <img src={arrLeft} alt=""/> Cancel payment
                        </p>
                        <img src={logo} alt=""/>
                    </div>
                </div>
                <div
                    className='border border-[#1289D9] rounded-[16px] py-16 px-[40px] space-y-6 shadow-[0px_2px_10px_0px_rgba(0,0,0,0.15)] w-[55%]'>
                    <h1 className='text-[#1289D9] text-[28px] font-bold mb-[4%]'>Payment by card</h1>
                    <h3 className='text-[20px] font-semibold mb-[20px]'>Card information</h3>
                    <div
                        className='flex items-center justify-between border border-[#B1B1B4] px-[20px] py-[4px]  rounded-[16px] focus:outline outline-2 outline-offset-2 focus:border-[red]'>
                        {/* <input type="text" className='w-full outline-none text-[14px] ' placeholder='Card number' /> */}
                        <TextField
                            label="Card number"
                            id="standard-size-small"
                            size="small"
                            variant="standard"
                            value={inputValue}
                            onChange={handleInputChange}
                            style={{width: "100%", marginBottom: "12px"}}
                            InputProps={{
                                disableUnderline: true,
                                style: {border: "none"},
                                notchedOutline: {border: "none"}
                            }}
                            inputProps={{
                                maxLength: 19,
                            }}
                            InputLabelProps={{
                                style: {color: '#B1B1B4'}
                            }}
                        />
                        <img src={checkIconCard(inputValue)} alt="card icon" style={{marginRight: '8px'}}/>
                    </div>
                    {!isCardNumberValid && <span className="text-red-500 text-sm">Invalid card number</span>}

                    <div className='flex items-center gap-[20px] mt-[10px]'>
                        <div
                            className='border border-[#B1B1B4] px-[20px] py-[4px] rounded-[16px] w-1/2 focus:border-[#26B3E9]'>
                            <TextField
                                label="Expire date"
                                id="standard-size-small"
                                size="small"
                                variant="standard"
                                style={{width: "50%", marginBottom: "12px"}}
                                InputProps={{
                                    disableUnderline: true, style: {border: "none"}
                                }}
                                InputLabelProps={{
                                    style: {color: '#B1B1B4'}
                                }}
                                value={expiry}
                                onChange={handleChange}
                                placeholder="MM/YY"
                            />
                        </div>
                    </div>
                    <button disabled={!isFormValid}
                            onClick={() => send_otp(verification_id, number, reverseExpiryDate(expiry), owner)}
                            className={`bg-[#1289D9] rounded-[16px] py-[4%] w-[100%] text-[18px] font-medium text-white mt-[4%] ${!isFormValid ? 'cursor-not-allowed opacity-50' : ''}`}>
                        Pay
                    </button>
                    <p className='w-[50%] text-center text-[16px] mx-auto mt-[20px]'>By clicking on the “Pay” button,
                        you agree to the terms of the <a className='font-semibold underline' href="">Offer</a></p>
                </div>
            </div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </div>
    )
}

export default PaymentByCard



