import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import PaymentByCard from './pages/PaymentByCard/PaymentByCard';
import PaymentByCardSm from './pages/PaymentByCard/PaymentByCardSm';
import SmsVerify from './pages/SmsVerify/SmsVerify';
import OrderPaid from './pages/OrderPaid/OrderPaid';
import PaymentDeclined from './pages/PaymentDeclined/PaymentDeclined';
import Wrong from './pages/Wrong/Wrong';


function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      setWindowWidth(window.innerWidth)
    })
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route element={windowWidth > 768 ? <PaymentByCard /> : <PaymentByCardSm />} path='/paymentByCard' />
        <Route element={<SmsVerify />} path='/smsVerify' />
        <Route element={<OrderPaid />} path='/orderPaid' />
        <Route element={<PaymentDeclined />} path='/paymentDeclined' />
        <Route element={<Wrong />} path='/wrong' />
      </Routes>
    </div>
  );
}

export default App;
