import React, {useEffect, useState} from 'react'
import logo from "../../assets/imgs/Logo light.png"
import arrLeft from "../../assets/icons/arrow-left.svg"
import passCheck from "../../assets/icons/password-check.svg"
import pciDss from "../../assets/imgs/pciDss.png"
import TextField from '@mui/material/TextField';
import axios from "axios";
import {toast, Toaster} from "react-hot-toast";
import {useLocation, useNavigate} from "react-router-dom";

function SmsVerify() {
    const [inputValue, setInputValue] = useState('');
    const location = useLocation();
    const navigate = useNavigate()
    const randomInt = Math.floor(Math.random() * 1000);
    const [counter, setCounter] = useState(59);
    const [isResendActive, setIsResendActive] = useState(false);
    const smsVerify = (payment_id, code) => {
        const url = 'https://gw.unipos.online/api/v1/jsonrpc'
        const headers = {
            'Content-Type': 'application/json'
        }
        const params = {
            "jsonrpc": "2.0",
            "id": randomInt,
            "method": "payment.verify.id",
            "params": {
                "payment_id": payment_id,
                "code": code
            }
        }
        axios.post(url, params, {headers})
            .then(response => {
                const info = response?.data?.result;
                const error = response?.data?.error;
                if (error) {
                    toast.error(error?.message, {
                        style: {
                            borderRadius: '10px',
                            background: '#c0392b',
                            color: '#fff',
                        },
                    })
                    // navigate('/paymentDeclined', {state: info})
                } else {
                    navigate('/orderPaid', {state: info});
                }
            })
            .catch(error => {
                console.log(error);
            })
    }


    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        } else {
            setIsResendActive(true);
        }
        return () => clearTimeout(timer);
    }, [counter]);

    const handleChange = (event) => {
        let value = event.target.value;
        value = value.replace(/\D/g, '').substring(0, 6);
        if (value.length > 3) {
            value = value.substring(0, 3) + ' ' + value.substring(3);
        }
        setInputValue(value);
    };

    const handleResend = () => {
        if (isResendActive) {
            setCounter(59);
            setIsResendActive(false);
            // Resend SMS logic goes here
            console.log("Resend code");
        }
    };
    return (
        <div className='px-[2%] md:px-[0]'>
            <div className='flex mt-[10%] items-center justify-between md:w-[40%] mx-auto md:mt-[2%] md:mb-[2%]'>
                <img src={logo} alt="" className='mx-auto'/>
            </div>
            <div className='mt-8 md:border border-[#1289D9] md:w-[40%] mx-auto py-[2%] px-[3%] rounded-[16px]'>
                <h1 className='text-[#1289D9] text-[28px] font-bold mb-[20px]'>
                    SMS confirmation
                </h1>
                <label className='text-[20px] font-semibold ' htmlFor="">
                    Verification code
                </label>
                <div
                    className='flex items-center justify-between mt-[4%] border border-[#B1B1B4] px-[25px] py-[4px] rounded-[16px]'>
                    <TextField
                        label="Verification Code"
                        id="standard-size-small"
                        size="medium"
                        variant="standard"
                        style={{width: "100%", marginBottom: "12px"}}
                        InputProps={{
                            disableUnderline: true,
                            style: {
                                border: "none",
                                textAlign: "center", // Center the digits inside the input field
                                fontSize: "20px",    // Adjust font size if necessary
                                letterSpacing: "15px", // Add spacing between the digits
                            },
                        }}
                        InputLabelProps={{
                            style: {color: '#B1B1B4'},
                        }}
                        value={inputValue}
                        onChange={handleChange}
                        placeholder="--- ---"
                        inputProps={{
                            maxLength: 7,
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            style: {textAlign: "center"}, // Ensure input text is centered
                        }}
                    />
                    <img src={passCheck} alt=""/>
                </div>
                <div className='flex items-center justify-between my-4'>
                    {/*<p className={`text-[20px] font-semibold ${isResendActive ? 'text-[#1289D9] cursor-pointer' : 'text-[#B1B1B4]'}`}*/}
                    {/*   onClick={handleResend}>*/}
                    {/*    Resend SMS*/}
                    {/*</p>*/}
                    {/*<p className='font-semibold text-[20px]'>{counter > 0 ? `${counter} sec` : ''}</p>*/}
                </div>
                {/*<p className='text-[20px] font-semibold mb-[3%]'>SMS sent to number:</p>*/}
                {/*<p className='text-[#1289D9] text-[20px] font-semibold mb-[10%]'>{location.state.phone}</p>*/}
                <div className='flex items-center justify-between gap-[20px]'>
                    {/*<button*/}
                    {/*    className='border border-[#B1B1B4] text-[18px] font-medium w-full rounded-[16px] py-[3%]'>Back*/}
                    {/*</button>*/}
                    <button className='bg-[#1289D9] text-[#fff] text-[18px] font-medium w-full rounded-[16px] py-[3%]'
                            onClick={() => smsVerify(location.state.verification_id, inputValue.replace(' ', ''))}
                    >
                        Pay
                    </button>
                </div>
            </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </div>
    )
}

export default SmsVerify