import React from 'react'
import logo from "../../assets/imgs/Logo light.png"
import checkCirc from "../../assets/icons/tick-circle.svg"
import docDownload from "../../assets/icons/document-download.svg"
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {PDFDownloadLink} from "@react-pdf/renderer";
import CheckPayment from "../CheckPayment/CheckPayment";
import {toast, Toaster} from "react-hot-toast";

function OrderPaid() {
    const location = useLocation();
    const {state} = location;
    console.log(state);
    const amount = (state?.amount / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    const ownerValue = state?.account[1]?.value || '';
    const [firstName, lastName] = ownerValue.split(' ');

    const navigate = useNavigate()
    setTimeout(() => {
        if (state && state?.redirect_url) {
            window.location.replace(state?.redirect_url)
        }
    }, 5000)

    return (
        <div>
            <div className='px-[2%] md:px-[0]'>
                <div className='flex mt-[10%] items-center justify-between md:w-[40%] mx-auto md:mt-[2%] md:mb-[2%]'>
                    <img src={logo} alt="" className='mx-auto'/>
                </div>

                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
            </div>

            <div
                className='md:border pb-10 top flex flex-col gap-[10px] md:shadow-[0px_2px_10px_0px_rgba(0,0,0,0.15)]  border-[#1289D9] rounded-[16px] mt-[1%] py-3 px-[5%] md:w-[45%] mx-[auto]'>
                <div className='flex items-center mx-auto gap-[20px] mt-[1%]'>
                    <img src={checkCirc} alt=""/>
                    <h1 className='text-[#27AE60] text-[24px] font-bold'>Order paid</h1>
                </div>
                <div className='h-[1px] bg-[#E7E8EA] my-[10px]'>

                </div>
                <h3 className='font-semibold text-[18px]'>
                    Order Description
                    <p className='text-[12px] font-normal'>Payment for order <br/><b>{state.ext_id}</b></p>
                </h3>
                <h3 className='font-semibold text-[18px]'>
                    Owner
                    <p className='text-[12px] font-normal'>{state?.account[1]?.value}</p>
                </h3>
                <h3 className='font-semibold text-[18px]'>
                    Receiver card
                    <p className='text-[12px] font-normal'>{state?.account[0]?.mask}</p>
                </h3>
                <h3 className='font-semibold text-[18px]'>
                    Transaction ID
                    <p className='text-[12px] font-normal'>{state?.payment?.ref_num}</p>
                </h3>
                <div className='h-[1px] bg-[#E7E8EA] my-[10px]'>

                </div>

                <h3 className='font-semibold text-[18px]'>
                    Comission
                    <p className='text-[12px] font-normal'>{state?.commission} UZS</p>
                </h3>
                <h3 className='font-semibold text-[18px]'>
                    Total amount
                    <p className='text-[20px] text-[#1289D9]'>{amount} UZS</p>
                </h3>
                <PDFDownloadLink
                    className="pt-8"
                    document={<CheckPayment
                        owner={state?.account[1]?.value}
                        receiverCard={state?.account[0]?.mask}
                        transactionId={state?.payment?.ref_num}
                        extID={state.ext_id}
                        commission={state?.commission}
                        total={amount}
                    />}
                    fileName={`${state.ext_id}.pdf`} // Provide a file name for the downloaded PDF
                >
                    {({blob, url, loading, error}) =>
                        loading ? (
                            <Button
                                className='w-full rounded-2xl !text-white !bg-blue-600 !py-4 !px-5 flex gap-2 !items-center !mt-5'
                                disabled>
                                Loading...
                            </Button>
                        ) : error ? (
                            <div>Error occurred while generating PDF</div>
                        ) : (
                            <Button
                                className='w-full rounded-2xl !text-white !bg-blue-600 !py-4 !px-5 flex gap-2 !items-center !mt-5'>
                                <img src={docDownload} alt=""/>
                                Download the cheque
                            </Button>
                        )
                    }
                </PDFDownloadLink>

            </div>
        </div>
    )
}

export default OrderPaid
