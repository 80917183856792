import React from 'react'
import logo from "../../assets/imgs/Logo light.png"
import closeCirc from "../../assets/icons/close-circle.svg"
import pciDss from "../../assets/imgs/pciDss.png"
import tryAgain from "../../assets/icons/rotate-right.svg"
import {useLocation} from "react-router-dom";

function PaymentDeclined() {
    const location = useLocation();
    console.log(location);
    const {state} = location;
    console.log(state);
    const amount = (state?.amount / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    const ownerValue = state?.account[1]?.value || '';
    const [firstName, lastName] = ownerValue.split(' ');
    return (
        <div>
            <div className='md:border top flex flex-col gap-[16px] md:shadow-[0px_2px_10px_0px_rgba(0,0,0,0.15)]  border-[#1289D9] rounded-[16px] mt-[1%] py-3 px-[5%]  md:w-[45%] mx-[auto]'>
                <img className='w-[97px] mx-auto' src={logo} alt="" />
                <div className='flex items-center mx-auto gap-[20px] mt-[1%]'>
                    <img src={closeCirc} alt="" />
                    <h1 className='text-[#FFC107] text-[24px] font-bold'>Payment declined</h1>
                </div>
                <div className='h-[1px] bg-[#E7E8EA] my-[10px]'>

                </div>
                <h3 className='font-semibold text-[18px]'>
                    Order Description
                    <p className='text-[12px] font-normal'>Payment for order <br/><b>{state?.ext_id}</b></p>
                </h3>
                <h3 className='font-semibold text-[18px]'>
                    Owner
                    <p className='text-[12px] font-normal'>{state?.account[1]?.value}</p>
                </h3>
                <h3 className='font-semibold text-[18px]'>
                    Receiver card
                    <p className='text-[12px] font-normal'>{state?.account[0]?.mask}</p>
                </h3>
                <h3 className='font-semibold text-[18px]'>
                    Transaction ID
                    <p className='text-[12px] font-normal'>{state?.payment?.ref_num}</p>
                </h3>
                <div className='h-[1px] bg-[#E7E8EA] my-[10px]'>

                </div>

                <h3 className='font-semibold text-[18px]'>
                    Comission
                    <p className='text-[12px] font-normal'>{state?.commission} UZS</p>
                </h3>
                <h3 className='font-semibold text-[18px]'>
                    Total amount
                    <p className='text-[20px] text-[#1289D9]'>{amount} UZS</p>
                </h3>
                <button
                    className='border border-[#B1B1B4] rounded-[16px] flex items-center justify-center gap-[12px]  py-5 font-medium text-[18px] mt-8'><img src={tryAgain} alt="" />Try again</button>
            </div>
            <img className='mx-auto my-[10%] md:my-[2%]' src={pciDss} alt="" />
        </div>
    )
}

export default PaymentDeclined