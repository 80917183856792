import React, {useMemo, useState} from 'react';
import TextField from '@mui/material/TextField';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import uzcardicon from "../../assets/icons/uzcard.svg";
import humoicon from "../../assets/icons/humo.svg";
import visaicon from "../../assets/icons/visa.svg";
import cardIcon from "../../assets/icons/card.svg";
import arrLeft from "../../assets/icons/arrow-left.svg"; // Import necessary assets
import logo from "../../assets/imgs/Logo light.png"; // Import necessary assets
import axios from "axios"; // Import necessary assets
import {toast, Toaster} from "react-hot-toast";
import ThemedSuspense from "../../components/ThemedSuspense";
import {isValidLuhn} from "../../utils/helper";

function PaymentByCardSm() {
    const [isEnabled, setIsEnabled] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [extId, setExtId] = useState('');
    const [amount, setAmount] = useState('');
    const [owner, setOwner] = useState('');
    const [error, setError] = useState('');
    const [number, setNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState(true);
    const [expireError, setExpireError] = useState(false);

    const navigate = useNavigate()
    const location = useLocation()

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const verification_id = urlParams.get('id');

    const [isOpen, setIsOpen] = useState(false); // Define isOpen state variable

    const toggleSwitch = () => {
        setIsEnabled(!isEnabled);
    };

    const randomInt = Math.floor(Math.random() * 1000);
    const randomUUID = crypto.randomUUID();

    const fetch_info = (id) => {
        const url = 'https://gw.unipos.online/api/v1/jsonrpc'
        const headers = {
            'Content-Type': 'application/json'
        }
        const params = {
            "jsonrpc": "2.0",
            "id": randomInt,
            "method": "payment.fetch.info",
            "params": {
                "payment_id": id
            }
        }
        axios.post(url, params, {headers})
            .then(response => {
                setExtId(response?.data?.result?.ext_id)
                setAmount(response?.data?.result?.amount)
            })
            .catch(error => {
                console.log(error);
            })

    }

    const send_otp = (verification_id, number, expire, owner) => {
        setLoading(true)
        const url = 'https://gw.unipos.online/api/v1/jsonrpc'
        const headers = {
            'Content-Type': 'application/json'
        }
        const params = {
            "jsonrpc": "2.0",
            "id": randomInt,
            "method": "payment.send.otp",
            "params": {
                "payment_id": verification_id,
                "number": number,
                "expire": expire,
                "owner_name": owner
            }
        }
        axios.post(url, params, {headers})
            .then(response => {
                setData(response.data.result)
                if (response.data.result) {
                    navigate('/smsVerify', {state: {verification_id: verification_id, phone: phone}})
                } else {
                    toast.error(response?.data?.error?.message, {
                        style: {
                            borderRadius: '10px',
                            background: '#c0392b',
                            color: '#fff',
                        },
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
        setLoading(false)
    }

    const formatInput = (value) => {
        return value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
    };

    const reverseExpiryDate = (expiryDate) => {
        const cleanDate = expiryDate.replace('/', ''); // Remove the slash
        const [month, year] = [cleanDate.slice(0, 2), cleanDate.slice(2)];
        return `${year}${month}`;
    };

    const handleInputChange = (e) => {
        const {value} = e.target;
        const formattedValue = formatInput(value);
        setInputValue(formattedValue);
        const plainNumber = formattedValue.replace(/\s/g, '');

        if (plainNumber.length === 16) {
            setIsCardNumberValid(isValidLuhn(plainNumber));
            if (isValidLuhn(plainNumber)) {
                setNumber(plainNumber)
            } else {
                setOwner(''); // Reset owner if card number is invalid
            }
        } else {
            setOwner('');
            setIsCardNumberValid(true)
        }
    };

    const formatExpireInput = (event) => {
        return event.replace(/\D/g, '').substring(0, 4);
    };

    const handleChange = (event) => {
        let value = event.target.value;

        // Remove all non-digits and limit string length to 4
        value = value.replace(/\D/g, '').substring(0, 4);

        // Add a slash after the first two digits
        if (value.length > 2) {
            value = value.substring(0, 2) + '/' + value.substring(2);
        }
        setExpiry(value);
    };

    const handleChangeCvv = (event) => {
        let value = event.target.value;

        // Remove all non-digits and limit string length to 3
        value = value.replace(/\D/g, '').substring(0, 3);

        setCvv(value);
    };

    const checkIconCard = (value) => {
        if (value.startsWith('8600')) {
            return uzcardicon;
        } else if (value.startsWith('6262')) {
            return uzcardicon;
        } else if (value.startsWith('9860')) {
            return humoicon;
        } else if (value.startsWith('4073')) {
            return humoicon;
        } else if (value.startsWith('5555')) {
            return humoicon;
        } else if (value.startsWith('5614')) {
            return uzcardicon;
        } else {
            return cardIcon;
        }
    };

    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-GB').replace(/\//g, '.');

    const isVisaCard = inputValue.startsWith('4934'); // Check if it's a Visa card

    const toggleDropdown = () => setIsOpen(!isOpen); // Define toggleDropdown function

    const isFormValid = !!inputValue && !!expiry && (!isVisaCard || !!cvv); // Example validation logic

    useMemo(() => {
        fetch_info(verification_id)
    }, [])

    return (
        <>
            {
                loading ? <ThemedSuspense/> :
                    <div className={`pt-[10%] pb-[50%] px-[24px] ${isOpen ? 'bg-black bg-opacity-50' : 'bg-transparent'}`}>
                        <div className='flex items-center justify-between '>

                            <img src={logo} alt="" className='mx-auto'/>
                        </div>
                        <p onClick={toggleDropdown}
                           className='flex mt-[6%] gap-[8px] items-center text-[14px] text-[#B1B1B4] cursor-pointer justify-center'>Order
                            summary <img className='rotate-[270deg]' src={arrLeft} alt=""/></p>
                        <div
                            className={`fixed inset-0 w-full h-full bg-black bg-opacity-50 ${isOpen ? 'block' : 'hidden'}`}
                            onClick={toggleDropdown}
                            style={{zIndex: 40}}
                        ></div>

                        <div
                            className={`fixed top-0 left-0 w-full bg-white z-50 transition-transform duration-500 ease-in-out ${isOpen ? 'translate-y-0' : '-translate-y-full'}`}>
                            <div className='z-[50] flex flex-col gap-[20px] px-[24px] pt-[10px] pb-[24px]'>
                                <p onClick={toggleDropdown}
                                   className='flex  gap-[8px] items-center text-[14px] text-[#B1B1B4] cursor-pointer justify-center'>Order
                                    summary <img className='rotate-[90deg]' src={arrLeft} alt=""/></p>
                                <h2 className='text-[#1289D9] text-[28px] font-bold'>Order summary</h2>
                                <h3 className='font-semibold text-[20px]'>
                                    Order Description
                                    <p className='text-[16px] font-normal'>Payment for order No. {extId} in the
                                        store</p>
                                </h3>
                                <h3 className='font-semibold text-[20px]'>
                                    Order date
                                    <p className='text-[16px] font-normal'>{formattedDate}</p>
                                </h3>
                                <div className='h-[1px] bg-[#E7E8EA]'>
                                </div>
                                <h3 className='font-semibold text-[20px]'>
                                    Total amount
                                    <p className='text-[24px] text-[#1289D9]'>{(amount / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} UZS</p>
                                </h3>
                            </div>
                        </div>
                        <h3 className='font-semibold text-center text-[20px] my-[5%]'>
                            Total amount
                            <p className='text-[24px] text-[#1289D9]'>{(amount / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} UZS</p>
                        </h3>
                        <div className=''>
                            <h3 className='text-[20px] font-semibold mb-[20px]'>Card information</h3>
                            <div
                                className={`flex items-center justify-between border ${isCardNumberValid ? 'border-[#B1B1B4]' : 'border-red-500'} px-[20px] py-[4px]  rounded-[16px] focus:border-[#26B3E9]`}>
                                <TextField
                                    label="Card number"
                                    id="standard-size-small"
                                    size="small"
                                    variant="standard"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    style={{width: "100%", marginBottom: "12px"}}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {border: "none"},
                                        notchedOutline: {border: "none"},
                                    }}
                                    inputProps={{
                                        maxLength: 19,
                                    }}
                                    InputLabelProps={{
                                        style: {color: '#B1B1B4'}
                                    }}
                                />
                                <img src={checkIconCard(inputValue)} alt="card icon" style={{marginRight: '8px'}}/>
                            </div>
                            {!isCardNumberValid && <span className="text-red-500 text-sm">Invalid card number</span>}
                            <span className="text-left text-base">{owner}</span>

                            <div className='flex items-center gap-[20px] mt-[20px]'>
                                <div
                                    className='border border-[#B1B1B4] px-[20px] py-[4px] rounded-[16px] w-full focus:border-[#26B3E9]'>
                                    <TextField
                                        label="Expire date"
                                        id="standard-size-small"
                                        size="small"
                                        variant="standard"
                                        style={{width: "50%", marginBottom: "12px"}}
                                        InputProps={{
                                            disableUnderline: true, style: {border: "none"}
                                        }}
                                        InputLabelProps={{
                                            style: {color: '#B1B1B4'}
                                        }}
                                        value={expiry}
                                        onChange={handleChange}
                                        placeholder="MM/YY"
                                    />
                                </div>
                            </div>
                            <button disabled={!isFormValid}
                                    onClick={() => send_otp(verification_id, number, reverseExpiryDate(expiry), owner)}
                                    className={`bg-[#1289D9] rounded-[16px] py-[4%] w-[100%] text-[18px] font-medium text-white mt-[4%] ${!isFormValid ? 'cursor-not-allowed opacity-50' : ''}`}>
                                Pay
                            </button>
                            <p className='w-[90%] text-center text-[16px] mx-auto mt-[20px]'>By clicking on the “Pay”
                                button, you
                                agree to the terms of the <a className='font-semibold underline' href="">Offer</a></p>
                        </div>
                        <Toaster
                            position="top-center"
                            reverseOrder={false}
                        />
                    </div>

            }
        </>

    );
}

export default PaymentByCardSm;
