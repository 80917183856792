import React from 'react';
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
        fontFamily: 'Helvetica',
        backgroundColor: '#fff'
    },
    section: {
        marginBottom: 16
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8
    },
    text: {
        fontSize: 12,
        marginBottom: 4
    },
    boldText: {
        fontWeight: 'bold'
    },
    divider: {
        height: 1,
        backgroundColor: '#E7E8EA',
        marginVertical: 10
    },
    totalAmount: {
        fontSize: 20,
        color: '#1289D9'
    },stamp: {
        padding: 10,
        border: '5px solid #2C7EC2',
        color: '#3498db',
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        transform: 'rotate(-2deg)'
    },
    stamptext1: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#2C7EC2',
        textTransform: 'uppercase',
        marginBottom: 8,
    },
    stamptext2: {
        fontSize: 26,
        fontWeight: 'bold',
        color: '#2C7EC2',
        textTransform: 'uppercase',
        letterSpacing: '-0.15px',
    }
});


const CheckPayment = ({
                          owner,
                          receiverCard,
                          transactionId,
                          commission,
                          total,
                          extID,
                      }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.header}>
                    <Text style={styles.title}>Transaction Receipt</Text>
                    <Text style={styles.title}>{}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Order Description</Text>
                    <Text style={styles.text}>Payment for order</Text>
                    <Text style={[styles.text, styles.boldText]}>{extID}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Owner</Text>
                    <Text style={styles.text}>{owner}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Receiver card</Text>
                    <Text style={styles.text}>{receiverCard}</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Transaction ID</Text>
                    <Text style={styles.text}>{transactionId}</Text>
                </View>

                <View style={styles.divider}/>

                <View style={styles.section}>
                    <Text style={styles.title}>Comission</Text>
                    <Text style={styles.text}>{commission} UZS</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Total amount</Text>
                    <Text style={[styles.text, styles.totalAmount]}>{total} UZS</Text>
                </View>
                <View style={styles.stamp}>
                    <Text style={styles.stamptext1}>IT UNISOFT GROUP</Text>
                    <Text style={styles.stamptext2}>PAID</Text>
                </View>
            </Page>
        </Document>
    );
};

export default CheckPayment;
